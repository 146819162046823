import React from 'react'
import { Link } from 'gatsby'
import Codepen from '../../components/embedCodepen'
import Layout from '../../components/layout'
import Header from '../../components/header'
import { Helmet } from 'react-helmet';




const Portfolio1 = ({data}) => (
  <Layout>
      <Codepen/>
      <Helmet>
      <title>{data.site.siteMetadata.demoUranusTitle} | {data.site.siteMetadata.title}</title>
      </Helmet>     
      <main className="page--demo demo--uranus">        
      <section className="slab slab--main">
            <Header siteTitle="Rob DiMarzo" /> 
            <div className="slab__inner">
                <div className="pageblock__content">   
                    <div className="pageblock__title">
                        <h1>Uranus CSS vs Image </h1>
                    </div>  
                    <p className="text--bold">A demo of how layered box-shadows can replicate spheres - with a space-theme!</p>
                    <p>
                        I've long admired Uranus and its creamy smooth atmosphere - so I decided to recreate it in CSS.
                    </p>
                    <p>
                        The spherical appearance is created through several inset box-shadows. Although it's not a perfect replica, I feel like I got it as close as I could while still keeping my sanity intact.                    
                    </p>
                    <p>
                        Hover over the CSS Uranus (on the right) to reveal its true nature.
                    </p>
                </div>
                <div className="pageblock__demo">
                    <p data-height="700" data-theme-id="36208" data-slug-hash="odZyEM" data-default-tab="result" data-user="robdimarzo" data-pen-title="Uranus CSS vs Image" className="codepen">See the Pen <a href="https://codepen.io/robdimarzo/pen/odZyEM/">Uranus CSS vs Image</a> by Rob DiMarzo (<a href="https://codepen.io/robdimarzo">@robdimarzo</a>) on <a href="https://codepen.io">CodePen</a>.</p>
                </div>
            </div>
    
        </section>
    </main>
  </Layout>
)
export const query = graphql`
  query UranusQuery {
    site {
      siteMetadata {
        title
        demoUranusTitle
      }
    }
  }
`
export default Portfolio1

